import edjsHTML from 'editorjs-html'

const edjsParser = edjsHTML(
    {
        header: (block) => `<h${block.data.level} class="title is-${block.data.level}">${block.data.text}</h${block.data.level}>`,
        paragraph: (block) => `<p class="mb-3">${block.data.text}</p>`,
        image: (block) => (
            `<figure class="image has-text-centered mt-5 mb-6${block.data.stretched ? ' is-fullwidth' : ''}">
                <img
                    src="${block.data.file.url}"
                    alt="${block.data.caption || ""}"
                    style="
                        border: ${block.data.withBorder ? "1px solid #EEF1FB" : "none"};
                        ${!block.data.stretched ? 'width: auto;' : 'width: 100%;'}
                        display: inline-block;
                    "
                />
                ${
                    block.data.caption &&
                        `<figcaption class="mt-2 is-size-7">
                            <div class="py-1 px-1" style="border: 1px solid #EEF1FB">
                                ${block.data.caption}
                            </div>
                        </figcaption>`
                }
            </figure>`
        )
    }
)

export default edjsParser