import React from "react"
import Layout from "../components/layout"
import editorJStoHTML from '../lib/editor-js-to-html'
import { Link } from "gatsby"
import Seo from "../components/seo"
import { getDateFormatted } from "../utils/date"

export default function Article({ pageContext: { article } }) {

  const html = editorJStoHTML.parse({ blocks: article.content }).join(' ')

  return (
    <Layout>
      <Seo title={article.meta.title}
        description={article.meta.description} />
      <div className="section">
        <div className="container" style={{ maxWidth: '760px' }}>
          <section className="section has-background-primary-light has-text-centered mb-6">
            <h1 className="title is-1 has-text-centered">{article.meta.title}</h1>
            <p className="subtitle has-text-centered is-6">
              posted on {getDateFormatted(article.dates.publication)}
            </p>

          </section>
          {
            <article dangerouslySetInnerHTML={{ __html: html }} />
          }
          <section className="section has-background-primary-light has-text-centered mt-6">
            <Link to='/news'>
              → <u>Read all latest news from the Open Membrane Database</u> ←
            </Link>
          </section>

        </div>
      </div>
    </Layout>
  )
}
